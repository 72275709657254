<template>
    <div class="v2-main">
        <div class="title">굿퍼슨 결혼중개 계약서</div>
        <div class="gap">
            <p>갑(가입자)</p>
            <div class="indent">
                <div class="name sign-space">
                    <div class="left">
                        <span>회원성명: </span>
                        <span class="m-l-16 spoqa-f-bold" v-html="me.name" />
                    </div>
                    <SignComponent :signStatus="signStatus" :sign="sign" :idx="1" id="main-sign" />
                </div>
            </div>
        </div>
        <div class="eul m-t-16">
            <p>을(사업자)</p>
            <div class="indent">
                <p>서비스명: {{ provider.service }}</p>
                <p>상호(운영사): {{ provider.company }}</p>
                <p>국내결혼신고번호: {{ provider.businessNum }}</p>
                <p>사업장주소: {{ provider.address }}</p>
                <div class="dv2fjek-kKrjeFeo">
                    <span class="m-r-4">대표자 성명: {{ provider.ceo }}</span>
                    <img width="81px" height="auto" :src="require('@/assets/images/app/stamp_offical.png')" alt="" />
                </div>
            </div>
            <p>갑과 을은 회원가입 계약(이하 “본 계약”)을 다음과 같이 체결합니다.</p>
        </div>
        <div class="today m-t-16">
            <span class="m-r-16">계약일: </span>
            <span class="spoqa-f-bold">{{ `${today.y} 년 ${today.m} 월 ${today.d} 일` }}</span>
        </div>
        <div class="contract-info">
            <div class="item" v-for="item in contractInfo" :key="item.content">
                <div class="num" v-html="`${item.id}. `" />
                <div class="content" v-html="item.content" />
            </div>
        </div>
        <div class="refund-info">
            <div class="refund-type" v-for="refund in refundInfo" :key="refund.title">
                <p>{{ refund.title }}</p>
                <div class="refund-item" v-for="item in refund.content" :key="item.case">
                    <div class="num" v-html="`${item.id}. `" />
                    <div class="content">
                        <div v-html="item.case" />
                        <div>
                            <div class="f-row" v-for="spec in item.spec" :key="`${spec.type}${spec.content}`">
                                <span class="num" v-html="spec.type" />
                                <span v-html="spec.content" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SignComponent from '@/components/app/SignComponent'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ContractMainV2',
    components: { SignComponent },
    props: {
        sign: String,
        today: Object,
        contractVersion: Object,
        signStatus: Object,
        userContract: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        userRatePlans() {
            const userRatePlanId =
                (this.userContract || {}).product_id || this.$store.getters.userRatePlans.pending[0].product_id
            const { name, pre_price: price, amount_original: amount, valid_date: validDate } = getPlanNameById(
                userRatePlanId
            )
            const pricePromoted = this.userContract.price || price

            return { name, price: pricePromoted, amount, validDate }
        },
        provider() {
            return this.contractVersion.contract.providerInfo
        },
        contractInfo() {
            const contractInfo = this.contractVersion.contract.contractInfo

            return contractInfo.map(item => {
                if (item.id === 1)
                    return {
                        id: item.id,
                        content: item.content
                            .replace('%a', this.userRatePlans.name)
                            .replace('%b', (this.userRatePlans.price * (10 / 11)).toLocaleString()),
                    }
                if (item.id === 3)
                    return {
                        id: item.id,
                        content: item.content.replace('%c', this.userRatePlans.amount),
                    }
                if (item.id === 4)
                    return {
                        id: item.id,
                        // content: item.content.replace('%d', this.userRatePlans.validDate),
                        content: item.content.replace('%d', this.userContract.valid_date),
                    }

                return { ...item }
            })
        },
        refundInfo() {
            return this.contractVersion.contract.refundInfo
        },
        terms() {
            return this.contractVersion.terms
        },
    },
}
</script>

<style scoped lang="scss">
.v2-main {
    padding-bottom: 40px;
    border-bottom: 1px solid $grey-03;
    margin-bottom: 40px;
    .title {
        text-align: center;
        margin-bottom: 12px;
        font-size: 18px;
        @include spoqa-f-bold;
    }
    .f-row {
        display: flex;

        .num {
            width: 16px;
        }
    }

    .dv2fjek-kKrjeFeo {
        display: flex;
        align-items: center;
    }

    .indent {
        margin-left: 16px;
    }
    .sign-space {
        display: flex;
        align-items: center;
    }

    .item,
    .refund-item {
        display: flex;
        .num {
            width: 5%;
        }
        .content {
            width: 90%;
        }
    }
    .contract-info {
    }
    .refund-info {
        margin-top: 16px;
        padding: 12px;
        background: $grey-01;
    }
}
</style>
